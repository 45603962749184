html {
  position: relative;
  min-height: 100%;
}

body {
  /* Margin bottom by footer height */
  min-height: 100%;
  margin-bottom: 80px;
}

.breadcrumb {
  background-color: #e4f3ef;
}

.breadcrumb a {
  color: #999999;
}

.breadcrumb a:hover {
  color: black;
}

.breadcrumb-item.active {
  color: #bbbbbb;
}

.cursor-pointer{
  cursor: pointer;
}